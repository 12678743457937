/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-unused-vars */
import HeaderMain from '../generalComponents/HeaderMain';
import { useEffect, useState } from 'react';
import { Box, FormControl, InputLabel, MenuItem, Modal, Select, TextField, Typography } from '@mui/material';
import api from '../generalComponents/api';
import DashboardSideTools from '../generalComponents/DashboardSideTools';
import { useNavigate } from 'react-router-dom';
import { jwtDecode } from 'jwt-decode';
import Autorizacao from '../generalComponents/Autorizacao';
import Swal from 'sweetalert2';
import DashboardDrawer from '../generalComponents/DashboardDrawer';
import dayjs from 'dayjs';

export default function Acessos() {
  const navigate = useNavigate();
  const [subUsers, setSubusers] = useState<{ [email: string]: { role: string } }>({});
  const [autorizacao, setAutorizacao] = useState(false);
  const [userRole, setUserRole] = useState('');
  //edição de usuário
  const [open, setOpen] = useState(false);
  const [telefone, setTelefone] = useState('');
  const [email, setEmail] = useState('');
  const [role, setRole] = useState("")
  const [password, setPassword] = useState("")
  //controle do drawer
  const [isOpenDrawer, setIsOpenDrawer] = useState(false);

  const handleClose = () => {
    setOpen(false);
  };

  useEffect(() => {
    const getSubusers = async () => {
      if (typeof document == 'undefined') return;
      if (typeof window == 'undefined') return;

      const domain = window.location.hostname;

      const response = await api
        .get('/cookie')
        .catch((error) => console.log(error));
      const _csrf = response?.data?.csrfToken;
      api.defaults.headers.common['X-CSRF-Token'] = _csrf;

      await api
        .get('/client/getAccountData', {
          params: {
            dominio: domain,
            accessToken:
              'rMRWYnD1vvy8TD61Yj9ClCvIZpB2PIg76XOLwd9sZNNJUh28QuOwUotj266294a8945557',
          },
        })
        .then((response) => {
          setSubusers(response.data.subusuarios);
        })
        .catch((error) => {
          if (error.response.data.code) {
            Swal.fire({
              position: 'top',
              icon: 'error',
              title: `ERRO - ${error.response.data.code}`,
              text: `${error.response.data.error}`,
            });
          }
        });
    };

    getSubusers();
  }, []);

  useEffect(() => {
    if (typeof document == 'undefined') {
      return;
    }
    if (typeof window == 'undefined') {
      return;
    }
    const authorization = async () => {
      const token = localStorage.getItem('appSession') || '';
      if (token == '') {
        setAutorizacao(false);
        return;
      }
      let decodeJwt = { email: '', role: "" };
      decodeJwt = jwtDecode(token);

      setUserRole(decodeJwt.role)

      if (decodeJwt.role !== 'admin') {
        setAutorizacao(false);
        return;
      }

      await api
        .get('/client/authorizate', { params: { email: decodeJwt.email } })
        .then((response) => {
          setAutorizacao(true);
        })
        .catch((error) => {
          setAutorizacao(false);
        });
    };
    authorization();
  }, []);

  const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    border: '1px solid #000',
    boxShadow: 24,
    p: 2,
    borderRadius: '20px',
  };

  const handleNewSubuser = async () => {
    if (typeof document == 'undefined') return;
    if (typeof window == 'undefined') return;

    const domain = window.location.hostname;

    const response = await api
      .get('/cookie')
      .catch((error) => console.log(error));
    const _csrf = response?.data?.csrfToken;
    api.defaults.headers.common['X-CSRF-Token'] = _csrf;

    const data = {
      email,
      password,
      role
    }

    await api
      .post('/signup', data)
      .then(async (response) => {
        await api.post('/client/newSubuser', { email, role, dominio: domain, accessToken: 'rMRWYnD1vvy8TD61Yj9ClCvIZpB2PIg76XOLwd9sZNNJUh28QuOwUotj266294a8945557' })
          .then((response) => {
            location.reload()
          }).catch((error) => {
            console.log(error)
          })
      })
      .catch((error) => {
        Swal.fire({
          position: 'top',
          icon: 'error',
          title: `ERRO - ${error.response.data.code}`,
          text: `${error.response.data.error}`,
        });
      });
  };

  const deleteSubuser = async (email: string) => {
    if (typeof document == 'undefined') return;
    if (typeof window == 'undefined') return;

    const domain = window.location.hostname;

    const response = await api
      .get('/cookie')
      .catch((error) => console.log(error));
    const _csrf = response?.data?.csrfToken;
    api.defaults.headers.common['X-CSRF-Token'] = _csrf;

    const data = {
      email,
      dominio: domain,
      accessToken: "rMRWYnD1vvy8TD61Yj9ClCvIZpB2PIg76XOLwd9sZNNJUh28QuOwUotj266294a8945557"
    }

    await api
      .delete('/client/deleteSubuser', { params: data })
      .then(async (response) => {
        location.reload()
      })
      .catch((error) => {
        console.log(error);
        Swal.fire({
          position: 'top',
          icon: 'error',
          title: `ERRO - ${error.response.data.code}`,
          text: `${error.response.data.error}`,
        });
      });
  };

  return (
    <>
      {!autorizacao ? (
        <>
          <Autorizacao />
        </>
      ) : (
        <>
          <Modal
            open={open}
            onClose={handleClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
          >
            <Box sx={style}>
              <Typography id="modal-modal-title" variant="h6" component="h2">
                Adicionar novo acesso
              </Typography>
              <Box
                component="form"
                sx={{
                  '& > :not(style)': { m: 1, width: '96%' },
                  width: '100%',
                }}
                noValidate
                autoComplete="off"
              >
                <TextField
                  autoFocus
                  id="outlined-basic"
                  label="Email"
                  variant="outlined"
                  color="success"
                  value={email}
                  onChange={(e) => {
                    setEmail(e.target.value);
                  }}
                />
              </Box>
              <Box
                component="form"
                sx={{
                  '& > :not(style)': { m: 1, width: '96%' },
                  width: '100%',
                }}
                noValidate
                autoComplete="off"
              >
                <TextField
                  autoFocus
                  type="password"
                  id="outlined-basic"
                  label="Senha"
                  variant="outlined"
                  color="success"
                  value={password}
                  onChange={(e) => {
                    setPassword(e.target.value);
                  }}
                />
              </Box>
              <Box
                component="form"
                sx={{
                  '& > :not(style)': { m: 1, width: '96%' },
                  width: '100%',
                }}
                noValidate
                autoComplete="off"
              >
                <FormControl fullWidth variant="outlined" color="success">
                  <InputLabel id="role-label">Cargo</InputLabel>
                  <Select
                    labelId="role-label"
                    id="role-select"
                    value={role}
                    onChange={(e) => setRole(e.target.value)}
                    label="Cargo"
                  >
                    <MenuItem value="admin">Admin</MenuItem>
                    <MenuItem value="promotor">Promotor</MenuItem>
                    <MenuItem value="suporte">Suporte</MenuItem>
                  </Select>
                </FormControl>
              </Box>
              <div className="flex w-full items-center justify-around">
                <button
                  onClick={() => handleClose()}
                  className="w-fit h-fit py-2 px-6 bg-red-500 text-white font-medium rounded-xl hover:bg-red-600 transition ease-in-out"
                >
                  Cancelar
                </button>
                <button
                  onClick={() => handleNewSubuser()}
                  className="w-fit h-fit py-2 px-6 bg-green-500 text-white font-medium rounded-xl hover:bg-green-600 transition ease-in-out"
                >
                  Novo acesso
                </button>
              </div>
            </Box>
          </Modal>
          <div className="flex flex-col w-full h-full bg-orange-50">
            <div className="flex flex-row justify-between w-screen p-5 hover:shadow-inner rounded-lg">
              <button onClick={() => navigate('/')} className="flex flex-row">
                <span className="">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="2em"
                    height="2em"
                    viewBox="0 0 21 21"
                  >
                    <path
                      fill="none"
                      stroke="#00662c"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="m7.405 13.5l-2.905-3l2.905-3m-2.905 3h9m-6-7l8 .002c1.104.001 2 .896 2 2v9.995a2 2 0 0 1-2 2l-8 .003"
                    />
                  </svg>
                </span>
                <span className="text-green-700 montserrat-500 m-1">
                  Voltar
                </span>
              </button>
              <button
                className="block md:hidden lg:hidden"
                onClick={() => setIsOpenDrawer(!isOpenDrawer)}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="1.9em"
                  height="1.9em"
                  viewBox="0 0 20 20"
                >
                  <path
                    fill="black"
                    d="M2 4.5a.5.5 0 0 1 .5-.5h15a.5.5 0 0 1 0 1h-15a.5.5 0 0 1-.5-.5m0 5a.5.5 0 0 1 .5-.5h15a.5.5 0 0 1 0 1h-15a.5.5 0 0 1-.5-.5m.5 4.5a.5.5 0 0 0 0 1h15a.5.5 0 0 0 0-1z"
                  />
                </svg>
              </button>
              <DashboardDrawer
                isOpen={isOpenDrawer}
                setIsOpen={setIsOpenDrawer}
                page="acessos"
                nivel={'1'}
                role={userRole}
              />
            </div>
            <div className="flex flex-col lg:flex-row w-full h-full p-2">
              <div className="hidden lg:flex md:flex flex-col min-w-96">
                <DashboardSideTools page="acessos" role="admin" />
              </div>
              <div className="flex flex-col w-full h-screen max-h-screen bg-white rounded-lg shadow-lg">
                <div className="flex flex-row w-full px-1 items-center justify-center">
                  <div className="flex flex-row w-full max-w-full">
                    <button className="bg-green-500 text-white font-medium max-w-full rounded-xl p-2 hover:bg-green-600 transition ease-in-out w-full m-2" onClick={(e) => setOpen(true)}>
                      Adicionar usuário
                    </button>
                  </div>
                </div>
                <div className="flex flex-col w-full h-full p-2 overflow-y-scroll">
                  {/* Usando Object.entries para transformar o objeto em array e mapear */}
                  {subUsers && typeof subUsers === "object" ? Object.entries(subUsers).map(([email, { role }]) => (
                    <div
                      className="flex flex-row bg-gray-100 justify-between w-full p-3 my-1 rounded-xl"
                      key={email}
                    >
                      <div className="flex flex-col text-sm md:flex-row lg:flex-row w-full justify-between">
                        <span>{email}</span>
                        <span>{role}</span>
                        <button className='!text-red-500 font-bolder' onClick={(e) => deleteSubuser(email)}>Excluir</button>
                      </div>
                    </div>
                  )) : <></>}
                </div>
              </div>
            </div>
          </div>
        </>
      )}
    </>
  );
}
