import DashboardSideTools from '../generalComponents/DashboardSideTools';
import Hotbar from './components/Hotbar';
import { useEffect, useState } from 'react';
import {
  Switch,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  Box,
  TextField,
} from '@mui/material';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import ImgDropzone from './components/ImgDropzone';
import api from '../generalComponents/api';
import { Masks } from '../Masks/masks';
import { useNavigate } from 'react-router-dom';
import dayjs, { Dayjs } from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import Autorizacao from '../generalComponents/Autorizacao';
import { jwtDecode } from 'jwt-decode';
import Swal from 'sweetalert2';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import { setTokenSourceMapRange } from 'typescript';

dayjs.extend(utc);
dayjs.extend(timezone);

export default function NewRaffle() {
  const navigate = useNavigate();
  const buttons = [
    'Geral',
    'Títulos',
    'Imagens',
    'Prêmios',
    'Afiliados',
    'Premiação Inst.',
    'Pagamento',
    'Promoções',
    'Anti Spam',
    'Suporte',
  ];
  const [selectedButton, setSelectedButton] = useState(0);
  const [titulo, setTitulo] = useState('');
  const [link, setLink] = useState('');
  const [subTitulo, setSubTitulo] = useState('');
  const [privacidade, setPrivacidade] = useState('publico');
  const [mostrarSorteio, setMostrarSorteio] = useState(false);
  const [categoria, setCategoria] = useState('carro');
  const [modeloReserva, setModeloReserva] = useState('aleatorio');
  const [modalidade, setModalidade] = useState('loteria');
  const [prioridade, setPrioridade] = useState(0);
  //Imagino que esse texto seja a descrição do sorteio
  const [texto, setTexto] = useState('');
  const [textoStatus, setTextoStatus] = useState('');
  const [inicioVendas, setInicioVendas] = useState('imediato');
  const [dataSorteio, setDataSorteio] = useState<Dayjs | null>(null);
  const [dataAgendamento, setDataAgendamento] = useState<Dayjs | null>(null);
  const [encerrarNaData, setEncerrarNaData] = useState(false);
  const [exibirData, setExibirData] = useState(false);
  const [certificado, setCertificado] = useState('0');
  //configuração dos campos
  const [requerNome, setRequerNome] = useState(true);
  const [requerEmail, setRequerEmail] = useState(true);
  const [requerTelefone, setRequerTelefone] = useState(true);
  // valores de cotas
  const [valorCotas, setValorCotas] = useState(0);
  const [quantidadeCotas, setQuantidadeCotas] = useState('100');
  const [tempoExpiracao, setTempoExpiracao] = useState('35');
  const [campanhaGratuita, setCampanhaGratuita] = useState(false);
  const [limiteCompras, setLimiteCompras] = useState('0');
  const [quantidadeMinima, setQuantidadeMinima] = useState('1');
  const [quantidadeMaxima, setQuantidadeMaxima] = useState(quantidadeCotas);
  const [cards, setCards] = useState(['', '', '', '', '', ''] as string[]);
  const [cardPupular, setCardPopular] = useState('0');
  const [barraProgresso, setBarraProgresso] = useState(false);
  const [rankDiario, setRankDiario] = useState(false);
  const [rankGeral, setRankGeral] = useState(false);
  const [exibirValorRanking, setExibirValorRanking] = useState(false);
  const [mostrarPremios, setMostrarPremios] = useState(false);
  const [vendaMinima, setVendaMinima] = useState('');
  const [manualmente, setManualmente] = useState(false);
  const [quantidadePremiosInst, setQuantidadePremiosInst] = useState(0);
  //imagens
  const [images, setImages] = useState([] as File[]);
  //premios
  const [premios, setPremios] = useState([] as string[]);
  const [tituloPremio, setTituloPremio] = useState('');
  //Afiliados
  const [permiteAfiliados, setPermiteAfiliados] = useState(false);
  const [restringirAfiliados, setRestringirAfiliados] = useState(false);
  //titulos premiados
  const [titulosPremiados, setTitulosPremiados] = useState(
    [] as Array<Array<string>>,
  );
  const [controleTitulos, setControleTitulos] = useState(false);
  //aux dos títulos premiados
  const [nomeTituloPremiado, setNomeTituloPremiado] = useState('');
  const [numeroTituloPremiado, setNumeroTituloPremiado] = useState('');
  const [gerandoTitulos, setGerandoTitulos] = useState(false);
  //fim dos aux

  const [ativarTitulosPremiados, setAtivarTitulosPremiados] = useState(false);
  const [mostrarTitulosPremiados, setMostrarTitulosPremiados] = useState(false);
  const [textoTitulosPremiados, setTextoTitulosPremiados] = useState('');
  //pagamento
  const [banco, setBanco] = useState('mercadopago');
  const [bankApiKey, setBankApiKey] = useState('');
  //promoções
  const [promocoes, setPromocoes] = useState([] as any);
  const [ativarPromocoes, setAtivarPromocoes] = useState(false);
  const [quantidadePromocao, setQuantidadePromocao] = useState('');
  const [valorPromocao, setValorPromocao] = useState('');
  //anti spam
  const [antiSpam, setAntiSpam] = useState(false);
  const [limiteReservas, setLimiteReservas] = useState('3');
  const [tempoBan, setTempoBan] = useState('3');
  //suporte
  const [widget, setWidget] = useState('textIcon');
  const [whatsApp, setWhatsApp] = useState('https://wa.me/+55');
  const [grupoWhatsApp, setGrupoWhatsApp] = useState(
    'https://chat.whatsapp.com/',
  );
  const [telegram, setTelegram] = useState('https://telegram.com/');
  const [Email, setEmail] = useState('exemplo@email.com');
  const [discord, setDiscord] = useState('https://discord.com/');
  const [instagram, setInstagram] = useState('https://instagram.com/');
  //mascaras
  const masks = new Masks();
  //configuração dos níveis
  const [nivel, setNivel] = useState('1');
  const [client, setClient] = useState({} as any);

  //state auxiliares
  const [enableCreateButton, setEnableCreateButton] = useState(true);
  const [autorizacao, setAutorizacao] = useState(false);
  const [userRole, setUserRole] = useState('');

  //começo das funções
  const getClientInfo = async () => {
    if (typeof document == 'undefined') return;
    if (typeof window == 'undefined') return;

    const domain = window.location.hostname;

    const response = await api
      .get('/cookie')
      .catch((error) => console.log(error));
    const _csrf = response?.data?.csrfToken;
    api.defaults.headers.common['X-CSRF-Token'] = _csrf;

    await api
      .get('/client/getAccountData', {
        params: {
          dominio: domain,
          accessToken:
            'rMRWYnD1vvy8TD61Yj9ClCvIZpB2PIg76XOLwd9sZNNJUh28QuOwUotj266294a8945557',
        },
      })
      .then((response) => {
        setClient(response.data);
        setNivel(response.data.nivel);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const handleMaxNumberQuantity = (quantidade: string) => {
    const qtdNumerica = parseInt(quantidade);

    let maxLimit;
    if (nivel == '0') {
      maxLimit = 10000;
    } else if (nivel == '1') {
      maxLimit = 20000;
    } else if (nivel == '2') {
      maxLimit = 30000;
    } else if (nivel == '3') {
      maxLimit = 45000;
    } else {
      maxLimit = 50000;
    }

    if (qtdNumerica > maxLimit) {
      setQuantidadeMaxima(maxLimit.toString());
    } else {
      setQuantidadeMaxima(quantidade);
    }
  };

  const handleInput = (e: any) => {
    const quantidade = masks.removeNonNumericChars(e.target.value);
    const qtdNumerica = parseInt(quantidade);

    let maxLimit;
    if (nivel == '1') {
      maxLimit = 20000;
    } else if (nivel == '2') {
      maxLimit = 30000;
    } else if (nivel == '3') {
      maxLimit = 45000;
    } else {
      maxLimit = 50000;
    }

    if (qtdNumerica > maxLimit) {
      e.target.value = maxLimit.toString();
      setQuantidadeMaxima(maxLimit.toString());
    } else {
      setQuantidadeMaxima(quantidade);
    }
  };

  const handlerControlCards = (card: string, index: number) => {
    card = masks.removeNonNumericChars(card);
    const obj = [...cards]; // Crie uma cópia do array `cards`
    obj[index] = card; // Modifique o item na cópia
    setCards(obj); // Defina o estado com a cópia modificada
  };

  const handleSubmitPremios = (e: React.FormEvent<HTMLButtonElement>) => {
    e.preventDefault();
    if (premios.length > 10) {
      Swal.fire({
      position: 'top',
      icon: 'error',
      title: 'Erro ao adicionar prêmio',
      text: 'Você pode adicionar no máximo 10 prêmios.',
      showConfirmButton: true,
      });
      return;
    }
    if (tituloPremio.trim() !== '') {
      premios.push(tituloPremio);
      setTituloPremio('');
    }
  };

  const handleRemovePremios = (index: number) => {
    setPremios((prevPremio) => prevPremio.filter((_, i) => i !== index));
  };

  const handleSubmitTitulosPremiados = (
    e: React.FormEvent<HTMLButtonElement>,
  ) => {
    e.preventDefault();
    const obj: string[] = [];
    if (numeroTituloPremiado.length >= quantidadeCotas.toString().length) {
      Swal.fire({
        icon: 'error',
        title: 'Erro',
        text: 'O número do título premiado deve menor que a quantidade de títulos do sorteio!',
      });
      return;
    }
    obj[0] = nomeTituloPremiado;
    obj[1] = masks.setZeroLeft(
      numeroTituloPremiado,
      quantidadeCotas.toString().length - 1,
    );
    if (
      nomeTituloPremiado.trim() !== '' &&
      numeroTituloPremiado.trim() !== ''
    ) {
      titulosPremiados.push(obj);
      setNomeTituloPremiado('');
      setNumeroTituloPremiado('');
    }
  };

  const handleRemoveTitulosPremiados = (index: number) => {
    setTitulosPremiados((prevTitulo) =>
      prevTitulo.filter((_, i) => i !== index),
    );
  };

  const handlerSubmitPromocoes = (e: React.FormEvent<HTMLButtonElement>) => {
    e.preventDefault();
    const obj = {
      quantidade: quantidadePromocao,
      valor: String(parseFloat(valorPromocao) / 100),
    };
    if (obj.quantidade.trim() !== '' && obj.valor.trim() !== '') {
      setPromocoes((prevPromocoes: any) => [...prevPromocoes, obj]); // Adiciona ao array existente
      setQuantidadePromocao('');
      setValorPromocao('');
    }
  };

  const handleRemovePromocoes = (index: number) => {
    setPromocoes((prevPromocao: any) =>
      prevPromocao.filter((_: any, i: any) => i !== index),
    );
  };

  const handleAleatoryGifts = (e: React.FormEvent<HTMLButtonElement>) => {
    e.preventDefault();
    setGerandoTitulos(true);
    const titulosAux = [...titulosPremiados];

    if (
      quantidadePremiosInst > parseInt(quantidadeCotas) ||
      quantidadePremiosInst > 200
    ) {
      Swal.fire({
        icon: 'error',
        title: 'Erro',
        text: 'A quantidade de prêmios instantâneos deve ser menor ou igual a quantidade de títulos do sorteio! Além disso, você pode adicionar no máximo 200 premiações instantâneas!',
      });
      return;
    }

    const uniqueNumbers = new Set(
      titulosPremiados.map((titulo) => titulo[1]), // Obtém os números existentes
    );

    for (let i = 0; i < quantidadePremiosInst; i++) {
      let randomNumber: number;
      let formattedNumber: string;

      // Gera números até encontrar um que não esteja no conjunto
      do {
        randomNumber = Math.floor(Math.random() * parseInt(quantidadeCotas));
        formattedNumber = masks.setZeroLeft(
          randomNumber.toString(),
          quantidadeCotas.toString().length - 1,
        );
      } while (uniqueNumbers.has(formattedNumber));

      // Adiciona o número gerado ao conjunto para evitar duplicações
      uniqueNumbers.add(formattedNumber);

      // Define o título premiado e o número
      const obj: string[] = ['100.00', formattedNumber];

      // Adiciona o título premiado à lista
      titulosAux.push(obj);
    }

    setGerandoTitulos(false);
    Swal.fire({
      icon: 'success',
      title: 'Prêmios instantâneos gerados com sucesso!',
      text: 'Os prêmios instantâneos foram gerados com sucesso!',
    });
    setTitulosPremiados(titulosAux);
  };

  const handleEditTitulosPremiados = (
    index: number,
    field: 'premiacao' | 'numero',
    value: string,
  ) => {
    const updatedTitulos = [...titulosPremiados]; // Cria uma cópia da lista atual
    if (field === 'premiacao') {
      updatedTitulos[index][0] = value; // Atualiza o nome da premiação
    } else if (field === 'numero') {
      updatedTitulos[index][1] = value; // Atualiza o número
    }
    setTitulosPremiados(updatedTitulos); // Atualiza o estado
  };

  const renderFormContent = () => {
    switch (selectedButton) {
      case 0:
        return (
          <div className="p-1 flex flex-col w-full h-full">
            <div className="text-xl w-full text-center m-1">
              Formulário Geral
            </div>
            <Box
              component="form"
              sx={{
                '& > :not(style)': { m: 1, width: '98%' },
                width: '100%',
              }}
              noValidate
              autoComplete="off"
            >
              <TextField
                id="outlined-basic"
                label="Título *"
                variant="outlined"
                color="success"
                value={titulo}
                onChange={(e: any) => setTitulo(e.target.value)}
              />
            </Box>
            <Box
              component="form"
              sx={{
                '& > :not(style)': { m: 1, width: '98%' },
                width: '100%',
              }}
              noValidate
              autoComplete="off"
            >
              <TextField
                id="outlined-basic"
                label="Subtítulo *"
                variant="outlined"
                color="success"
                value={subTitulo}
                onChange={(e: any) => setSubTitulo(e.target.value)}
              />
            </Box>
            <Box
              component="form"
              sx={{
                '& > :not(style)': { m: 1, width: '98%' },
                width: '100%',
              }}
              noValidate
              autoComplete="off"
            >
              <TextField
                id="outlined-basic"
                label="Link *"
                variant="outlined"
                color="success"
                value={link}
                onChange={(e: any) => setLink(e.target.value)}
              />
            </Box>
            <Box
              component="form"
              sx={{
                '& > :not(style)': { m: 1, width: '98%' },
                width: '100%',
              }}
              noValidate
              autoComplete="off"
            >
              <TextField
                id="outlined-basic"
                label="Prioridade *"
                variant="outlined"
                color="success"
                value={prioridade}
                onChange={(e: any) => setPrioridade(e.target.value)}
              />
            </Box>
            <div className="mt-2 px-2">
              <FormControl color="success" fullWidth>
                <InputLabel id="demo-simple-select-label">
                  Privacidade *
                </InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  label="Privacidade *"
                  value={privacidade}
                  onChange={(e: any) => setPrivacidade(e.target.value)}
                  color="success"
                >
                  <MenuItem value={'publico'}>Público</MenuItem>
                  <MenuItem value={'privado'}>Privado</MenuItem>
                </Select>
              </FormControl>
            </div>
            <div className="flex mt-4 items-center">
              <Switch
                checked={mostrarSorteio}
                color="success"
                onClick={() => setMostrarSorteio(!mostrarSorteio)}
              />
              <span>Mostrar sorteio na página principal *</span>
            </div>
            <div className="mt-4 px-2">
              <FormControl color="success" fullWidth>
                <InputLabel id="demo-simple-select-label">
                  Categoria *
                </InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  label="Categoria *"
                  value={categoria}
                  onChange={(e: any) => setCategoria(e.target.value)}
                  color="success"
                >
                  <MenuItem value={'carro'}>Carro</MenuItem>
                  <MenuItem value={'moto'}>Moto</MenuItem>
                  <MenuItem value={'dinheiro'}>Dinheiro</MenuItem>
                  <MenuItem value={'eletronico'}>Eletônico</MenuItem>
                  <MenuItem value={'celular'}>Celular</MenuItem>
                  <MenuItem value={'diversos'}>Diversos</MenuItem>
                  <MenuItem value={'outros'}>Outros</MenuItem>
                </Select>
              </FormControl>
            </div>
            <div className="mt-4 px-2">
              <FormControl color="success" fullWidth>
                <InputLabel id="demo-simple-select-label">
                  Modelo de reserva *
                </InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  label="Modelo de reserva *"
                  value={modeloReserva}
                  onChange={(e: any) => setModeloReserva(e.target.value)}
                  color="success"
                >
                  <MenuItem value={'aleatorio'}>Números aleatórios</MenuItem>
                </Select>
              </FormControl>
            </div>
            <div className="mt-4 px-2">
              <FormControl color="success" fullWidth>
                <InputLabel id="demo-simple-select-label">
                  Modalidade do sorteio *
                </InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  label="Modalidade do sorteio *"
                  value={modalidade}
                  onChange={(e: any) => setModalidade(e.target.value)}
                  color="success"
                >
                  <MenuItem value={'loteria'}>Loteria Federal</MenuItem>
                  <MenuItem value={'giroAleatorio'}>Giro da sorte</MenuItem>
                </Select>
              </FormControl>
            </div>
            <div className="max-h-full w-full p-2">
              <h1 className="my-1">Descrição do sorteio:</h1>
              <ReactQuill
                theme="snow"
                value={texto}
                onChange={(e) => setTexto(e)}
                placeholder='Escreva aqui a descrição do sorteio. Ex: "Sorteio de um carro zero km, participe e concorra!"'
              />
            </div>
            <div className="mt-2 px-2">
              <FormControl color="success" fullWidth>
                <InputLabel id="demo-simple-select-label">
                  Início das vendas *
                </InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  label="Início das vendas *"
                  value={inicioVendas}
                  onChange={(e: any) => setInicioVendas(e.target.value)}
                  color="success"
                >
                  <MenuItem value={'imediato'}>Imediatamente</MenuItem>
                  <MenuItem value={'agendado'}>Agendado</MenuItem>
                </Select>
              </FormControl>
            </div>
            {inicioVendas === 'agendado' ? (
              <>
                <div className="flex px-2 mt-4 w-full">
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DateTimePicker
                      label="Data de agendamento *"
                      value={dataAgendamento}
                      onChange={(newValue: Dayjs | null) => {
                        setDataAgendamento(newValue);
                      }}
                    />
                  </LocalizationProvider>
                </div>
              </>
            ) : (
              <></>
            )}
            <div className="flex px-2 mt-4 w-full">
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DateTimePicker
                  label="Data do sorteio *"
                  value={dataSorteio}
                  onChange={(newValue: Dayjs | null) => {
                    setDataSorteio(newValue);
                  }}
                />
              </LocalizationProvider>
            </div>
            <div className="flex mt-4 items-center">
              <Switch
                checked={encerrarNaData}
                color="success"
                onClick={() => setEncerrarNaData(!encerrarNaData)}
              />
              <span>Encerrar automaticamente na data do sorteio *</span>
            </div>
            <div className="flex mt-4 items-center">
              <Switch
                checked={exibirData}
                color="success"
                onClick={() => setExibirData(!exibirData)}
              />
              <span>Mostrar data do sorteio para participantes *</span>
            </div>
            <Box
              component="form"
              sx={{
                '& > :not(style)': { m: 1, width: '98%' },
                width: '100%',
              }}
              noValidate
              autoComplete="off"
            >
              <TextField
                id="outlined-basic"
                label="Processo SUSEP *"
                variant="outlined"
                color="success"
                value={certificado}
                onChange={(e: any) => setCertificado(e.target.value)}
              />
            </Box>
          </div>
        );
      case 1:
        return (
          <div className="p-1 flex flex-col w-full h-full">
            <div className="text-xl w-full text-center">
              Configuração dos títulos
            </div>
            <div className="flex mt-2 items-center">
              <Switch
                checked={campanhaGratuita}
                color="success"
                onClick={() => setCampanhaGratuita(!campanhaGratuita)}
              />
              <span>Campanha gratuita *</span>
            </div>
            <div className="flex flex-row max-w-full max-h-fit w-full">
              <Box
                component="form"
                sx={{
                  '& > :not(style)': { m: 1, width: '98%' },
                  width: '100%',
                }}
                noValidate
                autoComplete="off"
              >
                {campanhaGratuita ? (
                  <>
                    <TextField
                      id="outlined-basic"
                      label="Valor por título *"
                      variant="outlined"
                      color="success"
                      disabled
                      value={valorCotas}
                      type="number"
                      onChange={(e: any) => setValorCotas(e.target.value)}
                    />
                  </>
                ) : (
                  <>
                    <TextField
                      id="outlined-basic"
                      label="Valor por título *"
                      variant="outlined"
                      color="success"
                      type="number"
                      value={valorCotas}
                      onChange={(e: any) => setValorCotas(e.target.value)}
                    />
                  </>
                )}
              </Box>
              <div className="mt-2 px-2 w-full">
                <FormControl color="success" fullWidth>
                  <InputLabel id="demo-simple-select-label">
                    Quantidade de títulos *
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    label="Quantidade de títulos *"
                    value={quantidadeCotas}
                    onChange={(e: any) => setQuantidadeCotas(e.target.value)}
                    color="success"
                  >
                    <MenuItem value={'100'}>100</MenuItem>
                    <MenuItem value={'1000'}>1.000</MenuItem>
                    <MenuItem value={'10000'}>10 mil</MenuItem>
                    <MenuItem value={'100000'}>100 mil</MenuItem>
                    <MenuItem value={'1000000'}>1 Milhão</MenuItem>
                    {nivel != '0' ? (
                      <MenuItem value={'10000000'}>10 Milhões</MenuItem>
                    ) : (
                      <></>
                    )}
                  </Select>
                </FormControl>
              </div>
              <div className="mt-2 px-2 w-full">
                <FormControl color="success" fullWidth>
                  <InputLabel id="demo-simple-select-label">
                    Tempo de expiração *
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    label="Tempo de expiração *"
                    value={tempoExpiracao}
                    onChange={(e: any) => setTempoExpiracao(e.target.value)}
                    color="success"
                  >
                    <MenuItem value={'10'}>10 minutos</MenuItem>
                    <MenuItem value={'15'}>15 minutos</MenuItem>
                    <MenuItem value={'20'}>20 minutos</MenuItem>
                    <MenuItem value={'25'}>25 minutos</MenuItem>
                    <MenuItem value={'30'}>30 minutos</MenuItem>
                  </Select>
                </FormControl>
              </div>
            </div>
            <div className="flex flex-row w-full max-w-full">
              <Box
                component="form"
                sx={{
                  '& > :not(style)': { m: 1, width: '98%' },
                  width: '100%',
                }}
                noValidate
                autoComplete="off"
              >
                <TextField
                  id="outlined-basic"
                  label="Quantidade máxima de títulos por comprador * (0 = desativado)"
                  variant="outlined"
                  color="success"
                  value={limiteCompras}
                  onChange={(e: any) =>
                    setLimiteCompras(
                      masks.removeNonNumericChars(e.target.value),
                    )
                  }
                />
              </Box>
              <Box
                component="form"
                sx={{
                  '& > :not(style)': { m: 1, width: '98%' },
                  width: '100%',
                }}
                noValidate
                autoComplete="off"
              >
                <TextField
                  id="outlined-basic"
                  label="Quantidade mínima por reserva *"
                  variant="outlined"
                  color="success"
                  value={quantidadeMinima}
                  onChange={(e: any) =>
                    setQuantidadeMinima(
                      masks.removeNonNumericChars(e.target.value),
                    )
                  }
                />
              </Box>
              <Box
                component="form"
                sx={{
                  '& > :not(style)': { m: 1, width: '98%' },
                  width: '100%',
                }}
                autoComplete="off"
              >
                <TextField
                  id="outlined-basic"
                  label="Quantidade máxima por reserva *"
                  variant="outlined"
                  color="success"
                  value={quantidadeMaxima}
                  onChange={(e: any) =>
                    handleMaxNumberQuantity(
                      masks.removeNonNumericChars(e.target.value),
                    )
                  }
                  onInput={handleInput}
                  inputProps={{
                    max:
                      nivel == '1'
                        ? 20000
                        : nivel == '2'
                          ? 30000
                          : nivel == '3'
                            ? 45000
                            : 50000,
                    type: 'number',
                  }}
                />
              </Box>
              <Box
                component="form"
                sx={{
                  '& > :not(style)': { m: 1, width: '98%' },
                  width: '100%',
                }}
                autoComplete="off"
              >
                <TextField
                  id="outlined-basic"
                  label="Venda mínima do sorteio *"
                  variant="outlined"
                  color="success"
                  value={vendaMinima}
                  onChange={(e: any) => {
                    if (e.target.value < parseInt(quantidadeCotas)) {
                      setVendaMinima(
                        masks.removeNonNumericChars(e.target.value),
                      );
                    }
                  }}
                />
              </Box>
            </div>
            <div className="flex flex-col w-full my-4 max-w-full">
              <h1 className="mx-2 font-medium my-1">
                Configuração dos cards de seleção
              </h1>
              <div className="flex flex-row w-full">
                <Box
                  component="form"
                  sx={{
                    '& > :not(style)': { m: 1, width: '98%' },
                    width: '100%',
                  }}
                  noValidate
                  autoComplete="off"
                >
                  <TextField
                    id="outlined-basic"
                    label="Card 1 *"
                    variant="outlined"
                    color="success"
                    value={cards[0]}
                    onChange={(e: any) =>
                      handlerControlCards(e.target.value, 0)
                    }
                  />
                </Box>
                <Box
                  component="form"
                  sx={{
                    '& > :not(style)': { m: 1, width: '98%' },
                    width: '100%',
                  }}
                  noValidate
                  autoComplete="off"
                >
                  <TextField
                    id="outlined-basic"
                    label="Card 2 *"
                    variant="outlined"
                    color="success"
                    value={cards[1]}
                    onChange={(e: any) =>
                      handlerControlCards(e.target.value, 1)
                    }
                  />
                </Box>
                <Box
                  component="form"
                  sx={{
                    '& > :not(style)': { m: 1, width: '98%' },
                    width: '100%',
                  }}
                  noValidate
                  autoComplete="off"
                >
                  <TextField
                    id="outlined-basic"
                    label="Card 3 *"
                    variant="outlined"
                    color="success"
                    value={cards[2]}
                    onChange={(e: any) =>
                      handlerControlCards(e.target.value, 2)
                    }
                  />
                </Box>
              </div>
              <div className="flex flex-row w-full">
                <Box
                  component="form"
                  sx={{
                    '& > :not(style)': { m: 1, width: '98%' },
                    width: '100%',
                  }}
                  noValidate
                  autoComplete="off"
                >
                  <TextField
                    id="outlined-basic"
                    label="Card 4 *"
                    variant="outlined"
                    color="success"
                    value={cards[3]}
                    onChange={(e: any) =>
                      handlerControlCards(e.target.value, 3)
                    }
                  />
                </Box>
                <Box
                  component="form"
                  sx={{
                    '& > :not(style)': { m: 1, width: '98%' },
                    width: '100%',
                  }}
                  noValidate
                  autoComplete="off"
                >
                  <TextField
                    id="outlined-basic"
                    label="Card 5 *"
                    variant="outlined"
                    color="success"
                    value={cards[4]}
                    onChange={(e: any) =>
                      handlerControlCards(e.target.value, 4)
                    }
                  />
                </Box>
                <Box
                  component="form"
                  sx={{
                    '& > :not(style)': { m: 1, width: '98%' },
                    width: '100%',
                  }}
                  noValidate
                  autoComplete="off"
                >
                  <TextField
                    id="outlined-basic"
                    label="Card 6 *"
                    variant="outlined"
                    color="success"
                    value={cards[5]}
                    onChange={(e: any) =>
                      handlerControlCards(e.target.value, 5)
                    }
                  />
                </Box>
              </div>
            </div>
            <div className="mt-2 px-2 w-full">
              <FormControl color="success" fullWidth>
                <InputLabel id="demo-simple-select-label">
                  Card mais popular *
                </InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  label="Card mais popular *"
                  value={cardPupular}
                  onChange={(e: any) => setCardPopular(e.target.value)}
                  color="success"
                >
                  <MenuItem value={'0'}>1</MenuItem>
                  <MenuItem value={'1'}>2</MenuItem>
                  <MenuItem value={'2'}>3</MenuItem>
                  <MenuItem value={'3'}>4</MenuItem>
                  <MenuItem value={'4'}>5</MenuItem>
                  <MenuItem value={'5'}>6</MenuItem>
                </Select>
              </FormControl>
            </div>
            <div className="flex mt-2 items-center">
              <Switch
                checked={barraProgresso}
                color="success"
                onClick={() => setBarraProgresso(!barraProgresso)}
              />
              <span>Mostrar barra de progresso de vendas *</span>
            </div>
            <div className="flex mt-2 items-center">
              <Switch
                checked={rankDiario}
                color="success"
                onClick={() => setRankDiario(!rankDiario)}
              />
              <span>Mostrar ranking diário de compradores *</span>
            </div>
            <div className="flex mt-2 items-center pb-5">
              <Switch
                checked={rankGeral}
                color="success"
                onClick={() => setRankGeral(!rankGeral)}
              />
              <span>Mostrar ranking geral de compradores *</span>
            </div>
            <div className="flex mt-2 items-center pb-5">
              <Switch
                checked={exibirValorRanking}
                color="success"
                onClick={() => setExibirValorRanking(!exibirValorRanking)}
              />
              <span>Exibir valor em R$ nos rankings *</span>
            </div>
          </div>
        );
      case 2:
        return (
          <div className="p-1 flex flex-col w-full h-full">
            <div className="text-xl w-full text-center mb-2">
              Envie suas imagens *
            </div>
            <ImgDropzone images={images} setImages={setImages} />
          </div>
        );
      case 3:
        return (
          <div className="p-1 flex flex-col w-full h-full">
            <div className="text-xl w-full text-center">
              Informe os prêmios do sorteio
            </div>
            <div className="flex flex-col mt-2 w-full">
              <div className="flex flex-row w-full p-2 bg-sky-200 rounded-xl lg:items-center justify-center">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="2em"
                  height="2em"
                  viewBox="0 0 24 24"
                  className="mr-2 min-w-8 min-h-8"
                >
                  <path
                    fill="#0aa1ff"
                    d="M11 17h2v-6h-2zm1-8q.425 0 .713-.288T13 8t-.288-.712T12 7t-.712.288T11 8t.288.713T12 9m0 13q-2.075 0-3.9-.788t-3.175-2.137T2.788 15.9T2 12t.788-3.9t2.137-3.175T8.1 2.788T12 2t3.9.788t3.175 2.137T21.213 8.1T22 12t-.788 3.9t-2.137 3.175t-3.175 2.138T12 22"
                  />
                </svg>
                <h1 className="font-medium text-sky-800">
                  Informe quais os prêmios os participantes estarão concorrendo.
                  Você pode informar até 10 prêmios.
                </h1>
              </div>
            </div>
            <div className="flex mt-2 items-center">
              <Switch
                checked={mostrarPremios}
                color="success"
                onClick={() => setMostrarPremios(!mostrarPremios)}
              />
              <span>Mostrar prêmios na página do sorteio *</span>
            </div>
            <div className="flex flex-col items-center">
              <Box
                component="form"
                sx={{
                  '& > :not(style)': { m: 1, width: '98%' },
                  width: '100%',
                }}
                noValidate
                autoComplete="off"
                className="flex flex-col lg:flex-row"
              >
                <TextField
                  id="outlined-basic"
                  label="Prêmio *"
                  variant="outlined"
                  color="success"
                  value={tituloPremio}
                  onChange={(e) => setTituloPremio(e.target.value)}
                />
                <button
                  className="bg-green-500 text-xl w-fit text-white p-2 rounded-lg hover:bg-green-600 transition-all duration-200 ease-in-out"
                  onClick={handleSubmitPremios}
                >
                  Adicionar
                </button>
              </Box>
              <div className="mt-4 w-full">
                {premios.length > 0 && (
                  <div className="list-disc">
                    {premios.map((premio, index) => (
                      <div
                        key={index}
                        className="relative text-lg m-1 px-3 py-2 font-medium text-gray-700 bg-green-200 rounded-xl"
                      >
                        {premio}
                        <button
                          className="absolute top-2 right-2 text-white bg-red-600 rounded-full h-6 w-6 flex items-center text-center justify-center"
                          onClick={(e) => {
                            e.stopPropagation();
                            handleRemovePremios(index);
                          }}
                        >
                          &times;
                        </button>
                      </div>
                    ))}
                  </div>
                )}
              </div>
            </div>
          </div>
        );
      case 4:
        return (
          <div className="p-1 flex flex-col w-full h-full">
            <div className="text-xl w-full text-center">
              Configuração de afiliados
            </div>
            <div className="flex flex-col w-full mt-4">
              <div className="flex mt-2 items-center">
                <Switch
                  checked={permiteAfiliados}
                  color="success"
                  onClick={() => setPermiteAfiliados(!permiteAfiliados)}
                />
                <span>Permitir afiliados</span>
              </div>
              <div className="flex bg-green-100 rounded-xl p-3 mt-2 text-lg">
                O código de afiliado funciona da seguinte maneira:
                <br />
                <br /> Quando um usuário acessa o site através de um link de
                afiliado, o código de afiliado é passado como um parâmetro na
                URL, como no exemplo: https://site.com?afiliado=123.
                <br />
                <br /> O site então armazena esse código de afiliado em um
                cookie no navegador do usuário. Isso permite que o site rastreie
                que o usuário foi encaminhado por um afiliado específico.
                <br />
                <br /> Enquanto o cookie estiver ativo, o código de afiliado
                será associado a todas as interações do usuário no site,
                independentemente dos produtos ou páginas acessadas.
                <br />
                <br />
                Inclusive se o usuário sair do site e voltar mais tarde, ou
                acessar com outra conta, também será rastreado. No entanto, se o
                usuário limpar os cookies do navegador ou acessar o site de
                outra forma (por exemplo, de outro dispositivo), o código de
                afiliado não será passado e não será rastreado. Exceto se
                acessar através do link de afiliado.
                <br />
                <br /> Caso o usuário entre no site através de um novo código de
                afiliado, o cookie será atualizado para o novo código.
              </div>
            </div>
          </div>
        );
      case 5:
        return (
          <div className="p-1 flex flex-col w-full h-full">
            <div className="text-xl w-full text-center">
              Premiação instantânea
            </div>
            <div className="flex flex-col w-full mt-4">
              <div className="flex flex-col w-full">
                <div className="flex flex-row w-full p-2 bg-sky-200 rounded-xl lg:items-center justify-center">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="2em"
                    height="2em"
                    viewBox="0 0 24 24"
                    className="mr-2 min-w-8 min-h-8"
                  >
                    <path
                      fill="#0aa1ff"
                      d="M11 17h2v-6h-2zm1-8q.425 0 .713-.288T13 8t-.288-.712T12 7t-.712.288T11 8t.288.713T12 9m0 13q-2.075 0-3.9-.788t-3.175-2.137T2.788 15.9T2 12t.788-3.9t2.137-3.175T8.1 2.788T12 2t3.9.788t3.175 2.137T21.213 8.1T22 12t-.788 3.9t-2.137 3.175t-3.175 2.138T12 22"
                    />
                  </svg>
                  <h1 className="font-medium text-sky-800">
                    Cadastre Títulos Premiados para os participantes. Você pode
                    cadastrar de 1 até 200 títulos.
                  </h1>
                </div>
              </div>
              <div className="flex flex-col w-full mt-4">
                <div className="flex flex-row w-full p-2 lg:px-10 lg:py-2 bg-amber-100 rounded-xl lg:items-center justify-center">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="2rem"
                    height="2rem"
                    viewBox="0 0 24 24"
                    className="mr-2 min-w-8 min-h-8"
                  >
                    <path
                      fill="#ffca0a"
                      d="M2.725 21q-.275 0-.5-.137t-.35-.363t-.137-.488t.137-.512l9.25-16q.15-.25.388-.375T12 3t.488.125t.387.375l9.25 16q.15.25.138.513t-.138.487t-.35.363t-.5.137zM12 18q.425 0 .713-.288T13 17t-.288-.712T12 16t-.712.288T11 17t.288.713T12 18m0-3q.425 0 .713-.288T13 14v-3q0-.425-.288-.712T12 10t-.712.288T11 11v3q0 .425.288.713T12 15"
                    />
                  </svg>
                  <h1 className="font-medium text-amber-800">
                    <strong>Atenção:</strong> Os títulos premiados serão
                    liberados por padrão. Após o cadastro, você poderá liberar
                    os títulos premiados para os participantes conforme desejar.{' '}
                    <strong>
                      Não é possível definir novos títulos premiados após a
                      criação do sorteio!
                    </strong>
                  </h1>
                </div>
              </div>
              <div className="flex mt-4 items-center">
                <Switch
                  checked={ativarTitulosPremiados}
                  color="success"
                  onClick={() =>
                    setAtivarTitulosPremiados(!ativarTitulosPremiados)
                  }
                />
                <span>Ativar premiação instantânea *</span>
              </div>
              {ativarTitulosPremiados ? (
                <>
                  <div className="flex mt-4 items-center">
                    <Switch
                      checked={mostrarTitulosPremiados}
                      color="success"
                      onClick={() =>
                        setMostrarTitulosPremiados(!mostrarTitulosPremiados)
                      }
                    />
                    <span>Mostrar títulos premiados na página do sorteio</span>
                  </div>
                  <div className="flex mt-4 items-center">
                    <Switch
                      checked={controleTitulos}
                      color="success"
                      onClick={() =>
                        setControleTitulos(!controleTitulos)
                      }
                    />
                    <span>
                      Permitir bloqueio de saída de premiações instantâneas *
                    </span>
                  </div>
                  <Box
                    component="form"
                    sx={{
                      '& > :not(style)': { m: 1, width: '98%' },
                      width: '100%',
                    }}
                    noValidate
                    autoComplete="off"
                  >
                    {mostrarTitulosPremiados ? (
                      <>
                        <TextField
                          id="outlined-basic"
                          label="Texto a ser exibido para os ganhadores do títulos premiados *"
                          variant="outlined"
                          color="success"
                          value={textoTitulosPremiados}
                          onChange={(e: any) =>
                            setTextoTitulosPremiados(e.target.value)
                          }
                        />
                      </>
                    ) : (
                      <>
                        <TextField
                          id="outlined-basic"
                          label="Texto a ser exibido para os ganhadores do títulos premiados *"
                          variant="outlined"
                          color="success"
                          disabled
                          value={textoTitulosPremiados}
                          onChange={(e: any) =>
                            setTextoTitulosPremiados(e.target.value)
                          }
                        />
                      </>
                    )}
                  </Box>
                  <div className="flex mt-4 items-center">
                    <Switch
                      checked={manualmente}
                      color="success"
                      onClick={() => setManualmente(!manualmente)}
                    />
                    <span>Inserir manualmente os títulos</span>
                  </div>
                  <div className="flex flex-row w-full items-center">
                    {manualmente ? (
                      <>
                        <Box
                          component="form"
                          sx={{
                            '& > :not(style)': { m: 1, width: '98%' },
                            width: '100%',
                          }}
                          noValidate
                          autoComplete="off"
                        >
                          <TextField
                            id="outlined-basic"
                            label="Premiação do título premiado *"
                            variant="outlined"
                            color="success"
                            value={nomeTituloPremiado}
                            onChange={(e: any) =>
                              setNomeTituloPremiado(
                                masks.removeNonNumericCharsWithDot(
                                  e.target.value,
                                ),
                              )
                            }
                          />
                        </Box>
                        <Box
                          component="form"
                          sx={{
                            '& > :not(style)': { m: 1, width: '98%' },
                            width: '100%',
                          }}
                          noValidate
                          autoComplete="off"
                        >
                          <TextField
                            id="outlined-basic"
                            label="Número do título premiado * (Ex: 0000000)"
                            variant="outlined"
                            color="success"
                            value={numeroTituloPremiado}
                            onChange={(e: any) => {
                              if (
                                e.target.value.length <= quantidadeCotas.length
                              )
                                setNumeroTituloPremiado(
                                  masks.removeNonNumericChars(e.target.value),
                                );
                            }}
                          />
                        </Box>
                        <button
                          className="w-fit h-fit p-2 bg-green-500 text-white rounded-xl m-2 hover:bg-green-600 transition-all duration-200 ease-in-out"
                          onClick={(e) => handleSubmitTitulosPremiados(e)}
                        >
                          Adicionar
                        </button>
                      </>
                    ) : (
                      <>
                        <Box
                          component="form"
                          sx={{
                            '& > :not(style)': { m: 1, width: '98%' },
                            width: '100%',
                          }}
                          noValidate
                          autoComplete="off"
                        >
                          <TextField
                            id="outlined-basic"
                            label="Quantidade de prêmios instantâneos *"
                            variant="outlined"
                            color="success"
                            value={quantidadePremiosInst}
                            onChange={(e: any) =>
                              setQuantidadePremiosInst(
                                parseInt(
                                  masks.removeNonNumericChars(e.target.value),
                                ),
                              )
                            }
                          />
                        </Box>
                        <button
                          className="w-fit h-fit p-2 bg-green-500 text-white rounded-xl m-2 hover:bg-green-600 transition-all duration-200 ease-in-out"
                          onClick={(e) => handleAleatoryGifts(e)}
                        >
                          Gerar prêmios instantâneos aleatórios
                        </button>
                      </>
                    )}
                  </div>
                  <div className="flex flex-col mt-4">
                    <>
                      {gerandoTitulos ? (
                        <></>
                      ) : (
                        <>
                          {titulosPremiados.length > 0 &&
                            titulosPremiados.map((item, index) => (
                              <div
                                className="flex flex-row bg-green-200 w-full p-3 my-1 rounded-xl"
                                key={index}
                              >
                                <div className="relative font-medium text-green-900 w-full flex flex-row">
                                  <div className="flex flex-col max-w-1/2 m-4">
                                    <label className="text-sm text-gray-600">
                                      Premiação:
                                    </label>
                                    <input
                                      type="text"
                                      className="mb-2 p-2 border rounded w-fit"
                                      value={item[0]} // Controla o valor da premiação
                                      onChange={(e) =>
                                        handleEditTitulosPremiados(
                                          index,
                                          'premiacao',
                                          masks.removeNonNumericCharsWithDot(
                                            e.target.value,
                                          ),
                                        )
                                      } // Atualiza o campo de premiação
                                    />
                                  </div>
                                  <div className="flex flex-col m-4 max-w-1/2">
                                    <label className="text-sm text-gray-600">
                                      Número:
                                    </label>
                                    <input
                                      disabled
                                      type="text"
                                      className="p-2 border rounded w-fit"
                                      value={item[1]} // Controla o valor do número
                                      onChange={(e) =>
                                        handleEditTitulosPremiados(
                                          index,
                                          'numero',
                                          masks.removeNonNumericChars(
                                            e.target.value,
                                          ),
                                        )
                                      } // Atualiza o campo de número
                                    />
                                  </div>
                                  <button
                                    disabled={gerandoTitulos}
                                    className="absolute bottom-0 right-0 text-white bg-red-600 rounded-full h-6 w-6 flex items-center justify-center"
                                    onClick={(e) => {
                                      e.stopPropagation();
                                      handleRemoveTitulosPremiados(index);
                                    }}
                                  >
                                    &times;
                                  </button>
                                </div>
                              </div>
                            ))}
                        </>
                      )}
                    </>
                  </div>
                </>
              ) : (
                <></>
              )}
            </div>
          </div>
        );
      case 6:
        return (
          <div className="p-1 flex flex-col w-full h-full">
            <div className="text-xl w-full text-center">
              Configuração de pagamento
            </div>
            <div className="flex flex-col w-full mt-4">
              <div className="flex flex-row w-full p-2 bg-sky-200 rounded-xl lg:items-center justify-center">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="2em"
                  height="2em"
                  viewBox="0 0 24 24"
                  className="mr-2 min-w-8 min-h-8"
                >
                  <path
                    fill="#0aa1ff"
                    d="M11 17h2v-6h-2zm1-8q.425 0 .713-.288T13 8t-.288-.712T12 7t-.712.288T11 8t.288.713T12 9m0 13q-2.075 0-3.9-.788t-3.175-2.137T2.788 15.9T2 12t.788-3.9t2.137-3.175T8.1 2.788T12 2t3.9.788t3.175 2.137T21.213 8.1T22 12t-.788 3.9t-2.137 3.175t-3.175 2.138T12 22"
                  />
                </svg>
                <h1 className="font-medium text-sky-800">
                  Os pagamentos das reservas são processados exclusivamente por
                  meio dos provedores de pagamentos online, que executam a baixa
                  do pagamento de forma automática. Não são aceitas
                  transferências diretas; pedimos que todos os pagamentos sejam
                  feitos utilizando os provedores disponibilizados.
                </h1>
              </div>
            </div>
            <div className="mt-4 px-2 w-full">
              <FormControl color="success" fullWidth>
                <InputLabel id="demo-simple-select-label">
                  Plataforma de pagamento *
                </InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  label="Plataforma de pagamento *"
                  value={banco}
                  onChange={(e: any) => setBanco(e.target.value)}
                  color="success"
                >
                  <MenuItem value={'mercadopago'}>Mercado pago</MenuItem>
                  <MenuItem value={'efibank'}>Efí Pay (Efí Bank)</MenuItem>
                  <MenuItem value={'pay2m'}>Pay2m</MenuItem>
                </Select>
              </FormControl>
            </div>
            {banco == 'mercadopago' ||
            banco == 'efibank' ||
            banco == 'pay2m' ? (
              <></>
            ) : (
              <>
                <Box
                  component="form"
                  sx={{
                    '& > :not(style)': { m: 1, width: '98%' },
                    width: '100%',
                  }}
                  noValidate
                  autoComplete="off"
                >
                  <TextField
                    id="outlined-basic"
                    label="Token *"
                    variant="outlined"
                    color="success"
                    value={bankApiKey}
                    onChange={(e: any) => setBankApiKey(e.target.value)}
                  />
                </Box>
              </>
            )}
          </div>
        );
      case 7:
        return (
          <div className="p-1 flex flex-col w-full h-full">
            <div className="text-xl w-full text-center">
              Configuração de promoções
            </div>
            <div className="flex flex-col mt-4 w-full">
              <div className="flex flex-row w-full p-2 bg-sky-200 rounded-xl lg:items-center justify-center">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="2em"
                  height="2em"
                  viewBox="0 0 24 24"
                  className="mr-2 min-w-8 min-h-8"
                >
                  <path
                    fill="#0aa1ff"
                    d="M11 17h2v-6h-2zm1-8q.425 0 .713-.288T13 8t-.288-.712T12 7t-.712.288T11 8t.288.713T12 9m0 13q-2.075 0-3.9-.788t-3.175-2.137T2.788 15.9T2 12t.788-3.9t2.137-3.175T8.1 2.788T12 2t3.9.788t3.175 2.137T21.213 8.1T22 12t-.788 3.9t-2.137 3.175t-3.175 2.138T12 22"
                  />
                </svg>
                <h1 className="font-medium text-sky-800">
                  Ofereça para seus clientes opções de promoções. Você pode
                  cadastrar de 1 até 10 promoções. A promoção é aplicada em
                  forma de porcentagem, então escreva 10 se quiser aplicar 10%
                  de desconto sobre o valor do número de títulos. O sistema
                  converterá automaticamente para o formato decimal. LEMBRANDO
                  QUE 1% = 0.01, 10% = 0.1, 20% = 0.2 E ASSIM POR DIANTE.
                </h1>
              </div>
              <div className="flex mt-2 items-center">
                <Switch
                  checked={ativarPromocoes}
                  color="success"
                  onClick={() => setAtivarPromocoes(!ativarPromocoes)}
                />
                <span>Ativar promoções *</span>
              </div>
              {ativarPromocoes ? (
                <>
                  <div className="flex flex-col w-full">
                    <h1 className="px-2 montserrat-500">Promoções</h1>
                    <div className="flex flex-row w-full items-center">
                      <Box
                        component="form"
                        sx={{
                          '& > :not(style)': { m: 1, width: '98%' },
                          width: '100%',
                        }}
                        noValidate
                        autoComplete="off"
                      >
                        <TextField
                          id="outlined-basic"
                          label="Quantidade de títulos para promoção (Quantidade de títulos a ser aplicada desconto)*"
                          variant="outlined"
                          color="success"
                          value={quantidadePromocao}
                          onChange={(e: any) =>
                            setQuantidadePromocao(
                              masks.removeNonNumericChars(e.target.value),
                            )
                          }
                        />
                      </Box>
                      <Box
                        component="form"
                        sx={{
                          '& > :not(style)': { m: 1, width: '98%' },
                          width: '100%',
                        }}
                        noValidate
                        autoComplete="off"
                      >
                        <TextField
                          id="outlined-basic"
                          label="Porcentagem de desconto (O desconto é aplicado sobre o valor em forma de porcentagem Ex.: 10% de R$100,00 = R$ 10,00)*"
                          variant="outlined"
                          color="success"
                          value={valorPromocao}
                          onChange={(e: any) =>
                            setValorPromocao(
                              masks.removeNonNumericChars(e.target.value),
                            )
                          }
                        />
                      </Box>
                      <button
                        className="w-fit h-fit p-2 bg-green-500 text-white rounded-xl m-2 hover:bg-green-600 transition-all duration-200 ease-in-out"
                        onClick={(e) => handlerSubmitPromocoes(e)}
                      >
                        Adicionar
                      </button>
                    </div>
                    <div className="flex flex-col w-full">
                      {promocoes?.length > 0 ? (
                        <>
                          {promocoes?.map((item: any, index: number) => (
                            <div
                              className="flex flex-row bg-green-200 w-full p-3 my-1 rounded-xl"
                              key={index}
                            >
                              {
                                <>
                                  <span className="relative font-medium text-green-900 w-full">
                                    Quantidade: {item?.quantidade} - Desconto:{' '}
                                    {item?.valor * 100}%
                                    <button
                                      className="absolute bottom-0 right-0 text-white bg-red-600 rounded-full h-6 w-6 flex items-center text-center justify-center"
                                      onClick={(e) => {
                                        e.stopPropagation();
                                        handleRemovePromocoes(index);
                                      }}
                                    >
                                      &times;
                                    </button>
                                  </span>
                                </>
                              }
                            </div>
                          ))}
                        </>
                      ) : (
                        <></>
                      )}
                    </div>
                  </div>
                </>
              ) : (
                <></>
              )}
            </div>
          </div>
        );
      case 8:
        return (
          <div className="p-1 flex flex-col w-full h-full">
            <div className="text-xl w-full text-center">
              Configuração de anti-spam
            </div>
            <div className="flex flex-col w-full">
              <div className="flex flex-col w-full mt-4">
                <div className="flex flex-row w-full p-2 lg:px-10 lg:py-2 bg-sky-200 rounded-xl lg:items-center justify-center">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="2em"
                    height="2em"
                    viewBox="0 0 24 24"
                    className="mr-2 min-w-8 min-h-8"
                  >
                    <path
                      fill="#0aa1ff"
                      d="M11 17h2v-6h-2zm1-8q.425 0 .713-.288T13 8t-.288-.712T12 7t-.712.288T11 8t.288.713T12 9m0 13q-2.075 0-3.9-.788t-3.175-2.137T2.788 15.9T2 12t.788-3.9t2.137-3.175T8.1 2.788T12 2t3.9.788t3.175 2.137T21.213 8.1T22 12t-.788 3.9t-2.137 3.175t-3.175 2.138T12 22"
                    />
                  </svg>
                  <h1 className="font-medium text-sky-800">
                    O participante faz 3 reservas seguidas sem pagar, ele é
                    bloqueado e não pode fazer mais reservas até que o tempo de
                    bloqueio acabe.
                  </h1>
                </div>
              </div>
              <div className="flex flex-col w-full mt-4">
                <div className="flex flex-row w-full p-2 lg:px-10 lg:py-2 bg-amber-100 rounded-xl lg:items-center justify-center">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="2rem"
                    height="2rem"
                    viewBox="0 0 24 24"
                    className="mr-2 min-w-8 min-h-8"
                  >
                    <path
                      fill="#ffca0a"
                      d="M2.725 21q-.275 0-.5-.137t-.35-.363t-.137-.488t.137-.512l9.25-16q.15-.25.388-.375T12 3t.488.125t.387.375l9.25 16q.15.25.138.513t-.138.487t-.35.363t-.5.137zM12 18q.425 0 .713-.288T13 17t-.288-.712T12 16t-.712.288T11 17t.288.713T12 18m0-3q.425 0 .713-.288T13 14v-3q0-.425-.288-.712T12 10t-.712.288T11 11v3q0 .425.288.713T12 15"
                    />
                  </svg>
                  <h1 className="font-medium text-amber-800">
                    <strong>Atenção:</strong> O Anti Spam consiste em bloquear
                    temporariamente usuários que fazem muitas reservas sem
                    pagar. O bloqueio é feito por um tempo determinado e após o
                    tempo de bloqueio o usuário pode fazer reservas normalmente.
                  </h1>
                </div>
              </div>
              <div className="flex mt-2 items-center">
                <Switch
                  checked={antiSpam}
                  color="success"
                  onClick={() => setAntiSpam(!antiSpam)}
                />
                <span>Ativar Anti Spam *</span>
              </div>
              {antiSpam ? (
                <>
                  <Box
                    component="form"
                    sx={{
                      '& > :not(style)': { m: 1, width: '98%' },
                      width: '100%',
                    }}
                    noValidate
                    autoComplete="off"
                  >
                    <TextField
                      id="outlined-basic"
                      label="Limite de reservas sem pagar *"
                      variant="outlined"
                      color="success"
                      value={limiteReservas}
                      onChange={(e: any) => setLimiteReservas(e.target.value)}
                    />
                  </Box>
                  <div className="mt-2 px-2 w-full">
                    <FormControl color="success" fullWidth>
                      <InputLabel id="demo-simple-select-label">
                        Tempo de banimento *
                      </InputLabel>
                      <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        label="Tempo de banimento *"
                        value={tempoBan}
                        onChange={(e: any) => setTempoBan(e.target.value)}
                        color="success"
                      >
                        <MenuItem value={'1'}>1 Hora</MenuItem>
                        <MenuItem value={'2'}>2 Horas</MenuItem>
                        <MenuItem value={'3'}>3 Horas</MenuItem>
                        <MenuItem value={'4'}>4 Horas</MenuItem>
                        <MenuItem value={'5'}>5 Horas</MenuItem>
                        <MenuItem value={'6'}>6 Horas</MenuItem>
                        <MenuItem value={'24'}>1 Dia</MenuItem>
                      </Select>
                    </FormControl>
                  </div>
                </>
              ) : (
                <>
                  <Box
                    component="form"
                    sx={{
                      '& > :not(style)': { m: 1, width: '98%' },
                      width: '100%',
                    }}
                    noValidate
                    autoComplete="off"
                  >
                    <TextField
                      id="outlined-basic"
                      label="Limite de reservas sem pagar *"
                      variant="outlined"
                      color="success"
                      disabled
                      value={limiteReservas}
                      onChange={(e: any) => setLimiteReservas(e.target.value)}
                    />
                  </Box>
                  <div className="mt-2 px-2 w-full">
                    <FormControl color="success" fullWidth>
                      <InputLabel id="demo-simple-select-label">
                        Tempo de banimento *
                      </InputLabel>
                      <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        label="Tempo de banimento *"
                        value={tempoBan}
                        disabled
                        onChange={(e: any) => setTempoBan(e.target.value)}
                        color="success"
                      >
                        <MenuItem value={'1'}>1 Hora</MenuItem>
                        <MenuItem value={'2'}>2 Horas</MenuItem>
                        <MenuItem value={'3'}>3 Horas</MenuItem>
                        <MenuItem value={'4'}>4 Horas</MenuItem>
                        <MenuItem value={'5'}>5 Horas</MenuItem>
                        <MenuItem value={'6'}>6 Horas</MenuItem>
                        <MenuItem value={'24'}>1 Dia</MenuItem>
                      </Select>
                    </FormControl>
                  </div>
                </>
              )}
            </div>
          </div>
        );
      case 9:
        return (
          <div className="p-1 flex flex-col w-full h-full">
            <div className="text-xl w-full text-center">
              Configurações do suporte ao cliente
            </div>
            <div className="flex flex-col w-full">
              <div className="mt-2 px-2 w-full">
                <FormControl color="success" fullWidth>
                  <InputLabel id="demo-simple-select-label">
                    Tipo do widget *
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    label="Tipo do widget *"
                    value={widget}
                    onChange={(e: any) => setWidget(e.target.value)}
                    color="success"
                  >
                    <MenuItem value={'textIcon'}>Ícone e texto</MenuItem>
                    <MenuItem value={'icon'}>Apenas ícone</MenuItem>
                  </Select>
                </FormControl>
              </div>
              <Box
                component="form"
                sx={{
                  '& > :not(style)': { m: 1, width: '98%' },
                  width: '100%',
                }}
                noValidate
                autoComplete="off"
              >
                <TextField
                  id="outlined-basic"
                  label="Whatsapp *"
                  variant="outlined"
                  color="success"
                  value={whatsApp}
                  onChange={(e: any) => setWhatsApp(e.target.value)}
                />
              </Box>
              <Box
                component="form"
                sx={{
                  '& > :not(style)': { m: 1, width: '98%' },
                  width: '100%',
                }}
                noValidate
                autoComplete="off"
              >
                <TextField
                  id="outlined-basic"
                  label="Grupo do whatsapp *"
                  variant="outlined"
                  color="success"
                  value={grupoWhatsApp}
                  onChange={(e: any) => setGrupoWhatsApp(e.target.value)}
                />
              </Box>
              <Box
                component="form"
                sx={{
                  '& > :not(style)': { m: 1, width: '98%' },
                  width: '100%',
                }}
                noValidate
                autoComplete="off"
              >
                <TextField
                  id="outlined-basic"
                  label="Telegram *"
                  variant="outlined"
                  color="success"
                  value={telegram}
                  onChange={(e: any) => setTelegram(e.target.value)}
                />
              </Box>
              <Box
                component="form"
                sx={{
                  '& > :not(style)': { m: 1, width: '98%' },
                  width: '100%',
                }}
                noValidate
                autoComplete="off"
              >
                <TextField
                  id="outlined-basic"
                  label="Discord *"
                  variant="outlined"
                  color="success"
                  value={discord}
                  onChange={(e: any) => setDiscord(e.target.value)}
                />
              </Box>
              <Box
                component="form"
                sx={{
                  '& > :not(style)': { m: 1, width: '98%' },
                  width: '100%',
                }}
                noValidate
                autoComplete="off"
              >
                <TextField
                  id="outlined-basic"
                  label="Instagram *"
                  variant="outlined"
                  color="success"
                  value={instagram}
                  onChange={(e: any) => setInstagram(e.target.value)}
                />
              </Box>
            </div>
          </div>
        );
      default:
        return (
          <div className="p-1 flex flex-col w-full h-full">
            <div className="text-xl w-full text-center">Formulário Geral</div>
          </div>
        );
    }
  };

  const createRaffle = async () => {
    setEnableCreateButton(false);
    if (typeof window == 'undefined') return;
    if (typeof document == 'undefined') return;

    const dominio = window.location.hostname;

    let status;
    let lancamento;
    if (inicioVendas == 'imediato') {
      status = 'ativo';
      lancamento = false;
    } else {
      lancamento = true;
      status = 'aguardandoLancamento';
    }

    if (premios.length == 0) {
      Swal.fire({
        position: 'top',
        icon: 'error',
        title: 'Erro ao criar sorteio',
        text: 'Adicione pelo menos um prêmio.',
        showConfirmButton: true,
      });
      setEnableCreateButton(true);
      return;
    }

    const loginFields = {
      nome: requerNome,
      email: requerEmail,
      telefone: requerTelefone,
    };

    if (whatsApp == '') {
      setWhatsApp('https://wa.me/+55');
    }
    if (telegram == '') {
      setTelegram('https://t.me/');
    }
    if (discord == '') {
      setDiscord('https://discord.com/');
    }
    if (instagram == '') {
      setInstagram('https://instagram.com/');
    }
    if (grupoWhatsApp == '') {
      setGrupoWhatsApp('https://chat.whatsapp.com/');
    }

    const formData = new FormData();

    const dataSorteioBrasil = dayjs(dataSorteio)
      .tz('America/Sao_Paulo')
      .format();

    let dataAgendamentoBrasil;
    if (dataAgendamento != null)
      dataAgendamentoBrasil = dayjs(dataAgendamento)
        .tz('America/Sao_Paulo')
        .format();

    formData.append('dominio', dominio);
    formData.append('nome', titulo);
    formData.append('precoU', String(valorCotas));
    formData.append('qtdCotas', quantidadeCotas);
    formData.append('qtdCotasVendidas', '0');
    formData.append('porcentagemVendida', '0');
    formData.append('tempoExpiracao', tempoExpiracao);
    formData.append('dataSorteio', String(dataSorteioBrasil));
    formData.append('barraProgresso', String(barraProgresso));
    formData.append('rankingCompradores', String(rankGeral));
    formData.append('rankingDiario', String(rankDiario));
    formData.append('exibirValorRanking', String(exibirValorRanking));
    formData.append('subtitulo', subTitulo);
    formData.append('link', link);
    formData.append('cotasMin', quantidadeMinima);
    formData.append('cotasMax', quantidadeMaxima);
    formData.append('vendaMinima', vendaMinima);
    formData.append('mostrarSite', String(mostrarSorteio));
    formData.append('mostrarTitulo', String(mostrarTitulosPremiados));
    formData.append('controleTitulos', String(controleTitulos));
    formData.append('status', status);
    formData.append('tipo', categoria);
    formData.append('prioridade', String(prioridade));
    formData.append('grupoWpp', grupoWhatsApp);
    formData.append('grupoTelegram', 'empty');
    formData.append('certificado', certificado);
    formData.append('regulamento', 'empty');
    formData.append('capitalizadora', 'empty');
    formData.append('descricao', texto);
    formData.append('banco', banco);
    formData.append('ocultarDataSorteio', String(exibirData));
    formData.append('sorteioGratuito', String(campanhaGratuita));
    formData.append('metodoSorteio', modeloReserva);
    formData.append('modalidade', modalidade);
    formData.append('privacidade', privacidade);
    formData.append('lancamento', String(lancamento));
    formData.append('dataLancamento', String(dataAgendamentoBrasil));
    formData.append('encerrarAutomatico', String(encerrarNaData));
    formData.append('qtdMaxComprador', limiteCompras);
    formData.append(
      'accessToken',
      'rMRWYnD1vvy8TD61Yj9ClCvIZpB2PIg76XOLwd9sZNNJUh28QuOwUotj266294a8945557',
    );
    formData.append('mostrarPremios', String(mostrarPremios));
    formData.append('widget', widget);
    formData.append('wpp', whatsApp);
    formData.append('discord', discord);
    formData.append('instagram', instagram);
    formData.append('telegram', telegram);
    formData.append('loginFields', JSON.stringify(loginFields));

    promocoes.forEach((promocao: any, index: number) => {
      formData.append(`desconto[${index}][quantidade]`, promocao?.quantidade);
      formData.append(`desconto[${index}][valor]`, promocao?.valor);
    });

    cards.forEach((valor, index) => {
      formData.append(`pacoteCotas[${index}][quantidade]`, String(valor));
      formData.append(`pacoteCotas[${index}][indicador]`, String(false));
    });

    formData.set(
      `pacoteCotas[${parseInt(cardPupular)}][indicador]`,
      String(true),
    );

    images.forEach((image, index) => {
      formData.append(`imagens`, image);
    });

    titulosPremiados.forEach(([nome, titulo], index) => {
      formData.append(`cotasPremiadas[${index}][nome]`, nome);
      formData.append(`cotasPremiadas[${index}][titulo]`, titulo);
      formData.append(`cotasPremiadas[${index}][status]`, 'liberado');
    });

    formData.append('afiliados', String(permiteAfiliados));

    if (antiSpam) {
      formData.append('antiSpam[limiteReservas]', limiteReservas);
      formData.append('antiSpam[tempoBan]', tempoBan);
      formData.append('antiSpam[ativado]', 'true');
    } else {
      formData.append('antiSpam[ativado]', 'false');
    }

    premios.forEach((premio, index) => {
      formData.append(`premios[${index}][titulo]`, premio);
    });

    const response = await api
      .get('/cookie')
      .catch((error) => console.log(error));
    const _csrf = response?.data?.csrfToken;
    api.defaults.headers.common['X-CSRF-Token'] = _csrf;

    await api
      .post('/raffles/newRaffle', formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      })
      .then((res) => {
        Swal.fire({
          title: 'Sorteio criado!',
          icon: 'success',
          text: 'O sorteio foi criado com sucesso!',
        }).then(() => {
          window.location.reload();
        });
      })
      .catch((error) => {
        setEnableCreateButton(true);
        console.log(error);
        if (error.response.data.code) {
          Swal.fire({
            position: 'top',
            icon: 'error',
            title: `ERRO - ${error.response.data.code}`,
            text: `${error.response.data.error}`,
          });
        } else {
          Swal.fire({
            position: 'top',
            icon: 'error',
            title: `ERRO`,
            text: `${error.response.data.errors[0].msg}`,
          });
        }
      });
  };

  useEffect(() => {
    if (typeof document == 'undefined') {
      return;
    }
    if (typeof window == 'undefined') {
      return;
    }
    const authorization = async () => {
      const token = localStorage.getItem('appSession') || '';
      if (token == '') {
        setAutorizacao(false);
        return;
      }
      let decodeJwt = { email: '', role: '' };
      decodeJwt = jwtDecode(token);

      setUserRole(decodeJwt.role);

      if (decodeJwt.role == 'suporte') {
        setAutorizacao(false);
        return;
      }

      await api
        .get('/client/authorizate', { params: { email: decodeJwt.email } })
        .then((response) => {
          setAutorizacao(true);
        })
        .catch((error) => {
          setAutorizacao(false);
        });
    };
    authorization();
    getClientInfo();
  }, []);

  return (
    <>
      {!autorizacao ? (
        <>
          <Autorizacao />
        </>
      ) : (
        <>
          <div className="flex flex-col bg-orange-50 w-full max-h-screen h-screen max-w-full">
            <div className="flex mt-1 flex-col w-full h-10 max-w-full max-h-full justify-center">
              <button
                onClick={() => navigate('/')}
                className="flex flex-row ml-5 w-fit hover:shadow-inner rounded-lg"
              >
                <span className="">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="2em"
                    height="2em"
                    viewBox="0 0 21 21"
                  >
                    <path
                      fill="none"
                      stroke="#00662c"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="m7.405 13.5l-2.905-3l2.905-3m-2.905 3h9m-6-7l8 .002c1.104.001 2 .896 2 2v9.995a2 2 0 0 1-2 2l-8 .003"
                    />
                  </svg>
                </span>
                <span className="text-green-700 montserrat-500 m-1">
                  Voltar
                </span>
              </button>
            </div>
            <div className="flex flex-row w-full max-h-full p-2">
              <div className="hidden lg:block min-w-fit max-h-full overflow-x-hidden overflow-y-scroll">
                <DashboardSideTools page="sorteios" role={userRole} />
              </div>
              <div className="flex flex-col w-full max-h-screen p-1">
                <div className="my-1 flex flex-col p-2 w-full min-h-40 max-h-40 bg-white rounded-lg shadow-md items-center justify-center">
                  <h1 className="montserrat-500">Preview do sorteio</h1>
                  <div className="flex flex-row w-full">
                    {images.length != 0 ? (
                      <>
                        <img
                          className="large-thumb-photo rounded-lg shadow-md"
                          src={URL.createObjectURL(images[0])}
                          alt="sorteio"
                        />
                      </>
                    ) : (
                      <></>
                    )}
                    <div className="flex flex-row max-w-full max-h-full">
                      <div className="px-2 flex flex-col border-r border-gray-500">
                        <h1 className="montserrat-500">Título: {titulo}</h1>
                        <p>Sub título: {subTitulo}</p>
                        <p>link: {link}</p>
                        <p>
                          Data do sorteio:{' '}
                          {dayjs(dataSorteio)
                            .tz('America/Sao_Paulo')
                            .format('DD/MM/YYYY HH:mm')}
                        </p>
                      </div>
                      <div className="px-2 hidden md:flex lg:flex flex-col border-r border-gray-500">
                        <p>R$ {valorCotas} por título</p>
                        <p>{quantidadeCotas}</p>
                        <p>{quantidadeMinima}</p>
                        <p>{quantidadeMaxima}</p>
                      </div>
                      <div className="px-2 hidden md:flex lg:flex flex-col border-r border-gray-500">
                        <p>Prêmios (Principal em primeiro):</p>
                        <p>{premios[0]}</p>
                        <p>{premios[1]}</p>
                        <p>{premios[2]}</p>
                      </div>
                      <div className="px-2 hidden md:flex lg:flex flex-col">
                        <p>Banco:</p>
                        <p>{banco}</p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="my-1 flex flex-col p-1 w-full min-h-20 h-20 justify-center items-center bg-white rounded-lg shadow-md">
                  <h1 className="text-orange-700 montserrat-500 text-2xl">
                    Novo sorteio
                  </h1>
                  <div className="flex flex-row justify-between w-full p-1">
                    <Hotbar buttons={buttons} onSelect={setSelectedButton} />
                  </div>
                </div>
                <div className="my-1 flex flex-col p-1 w-full h-full max-h-full bg-white rounded-lg shadow-md">
                  <div className="flex w-full h-full max-h-screen lg:max-h-96 overflow-y-scroll">
                    {renderFormContent()}
                  </div>
                  <div className="flex w-full p-2 justify-end items-end">
                    {enableCreateButton ? (
                      <>
                        <button
                          className="bg-green-500 text-white p-2 rounded-lg hover:bg-green-700 transition-all duration-200 ease-in-out"
                          onClick={createRaffle}
                        >
                          Criar Sorteio
                        </button>
                      </>
                    ) : (
                      <>
                        <button className="bg-green-700 text-white p-2 rounded-lg">
                          Criando sorteio...
                        </button>
                      </>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      )}
    </>
  );
}
